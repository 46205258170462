<template>
  <v-card tile>
    <v-card-title></v-card-title> 
      <v-card-text class="text-center">
         <div class="natural--text text--darken-3 text-h5">สายเรียกเข้าจาก</div>
         <div class="secondary--text text-title-3">{{currentCall.incomingCall.callerInfo.displayName}}</div>
      </v-card-text>
      <v-card-actions ><v-spacer/><v-btn fab class="mx-2" @click="reject"><v-icon  color="error"> mdi-close-outline</v-icon></v-btn><v-btn fab class="mx-2" @click="acceptVideoCall"><v-icon  style="color:#245DDB"> mdi-video</v-icon></v-btn><v-btn fab class="mx-2" @click="acceptVoiceCall"><v-icon  style="color:#245DDB"> mdi-phone</v-icon></v-btn><v-spacer/></v-card-actions>
       <v-dialog v-model="acceptCall" fullscreen scrollable>
         <v-card tile class="fill-height">
           <v-card-title class="secondary--text text-title-3"><v-spacer/>{{currentCall.incomingCall.callerInfo.displayName}}<v-spacer/></v-card-title>
             <v-card-text>
             <div class="relative" ref="remoteVideo">
                <div class="absolute" ref="localVideo"></div>
              </div>
             </v-card-text>
           <v-card-actions><v-spacer/><v-btn fab class="mx-2" @click="hangUp"><v-icon  color="error"> mdi-phone-hangup-outline</v-icon></v-btn>
           <v-btn v-if="!mute" fab class="mx-2" @click="muteMic"><v-icon  style="color:#245DDB"> mdi-microphone</v-icon></v-btn>
           <v-btn v-else fab class="mx-2" @click="unMuteMic"><v-icon  style="color:#245DDB"> mdi-microphone-off</v-icon></v-btn>

       <v-btn v-if="!videoMode" fab class="mx-2" @click="startVideo"><v-icon  style="color:#245DDB"> mdi-video-off</v-icon></v-btn>
       <v-btn v-else fab class="mx-2" @click="stopVideo"><v-icon  style="color:#245DDB"> mdi-video</v-icon></v-btn>
       <v-spacer/></v-card-actions>
         </v-card>
       </v-dialog>
  </v-card>
</template>

<script>

import { VideoStreamRenderer, LocalVideoStream} from "@azure/communication-calling";
export default {
    props: ['callingId', 'currentCall'],
    data() {
      return {
        call: null,
        acceptCall: false,
        rendererRemote: null,
        rendererLocal: null,
        localVideoStream: null,
        videoMode:false,
        mute: false
      }
    },
    methods: {
      async acceptVoiceCall () {
        try {
        this.currentCall.incomingCall.on('callEnded', this.callEnd)   
        const addedCall = await this.currentCall.incomingCall.accept()
        this.videoMode = false
        this.call = addedCall
        this.acceptCall = true
        this.subscribeToRemoteParticipantInCall(addedCall)
        this.subscribeToStateChanged(addedCall)
        this.subscribeToLocalVideoStreams(addedCall)
        } catch (error) {
          console.log(error)
          this.$emit('onClose')
        }
      },
      async acceptVideoCall () {
        try {
          this.localVideoStream =  await this.createLocalVideoStream()
          this.placeCallOptions = {videoOptions: {localVideoStreams:[this.localVideoStream]}}
          this.currentCall.incomingCall.on('callEnded', this.callEnd)
          const addedCall = await this.currentCall.incomingCall.accept(this.placeCallOptions)
          this.videoMode = true
          this.call = addedCall
          this.acceptCall = true
          this.subscribeToRemoteParticipantInCall(addedCall)
          this.subscribeToStateChanged(addedCall)
          this.localVideoView()
          this.subscribeToLocalVideoStreams(addedCall)
        } catch (error) {
           console.log(error)
          this.$emit('onClose')
        }
      },
      callEnd () {
        console.log('hangup')
        this.$emit('onClose')
      },
      async muteMic () {
        await this.call.mute()
        this.mute = true
      },
      async unMuteMic() {
        await this.call.unmute()
        this.mute = false
      },
      async startVideo () {
        if(this.call) {
          try {
          this.localVideoStream = await this.createLocalVideoStream()
          await this.call.startVideo(this.localVideoStream);
           this.videoMode = true
          } catch (error) {
              console.error(error);
          }
        }
      },
      async stopVideo () {
         try {
         await this.call.stopVideo(this.localVideoStream);
          this.videoMode = false
        } catch (error) {
            console.error(error);
        }
      },
      async createLocalVideoStream () {
         const camera = ( await window.deviceManager.getCameras())[0];
          if (camera) {
              return new LocalVideoStream(camera);
          } else {
              console.error(`No camera device found on the system`);
          }
      },
      async placeVideoCall () {
          try {
            const videoDevices = await window.deviceManager.getCameras()
            const videoDeviceInfo = videoDevices[0];
            this.localVideoStream = new LocalVideoStream(videoDeviceInfo)
            this.placeCallOptions = {videoOptions: {localVideoStreams:[this.localVideoStream]}}
            this.call = window.callAgent.startCall([{ communicationUserId: this.callingId }], this.placeCallOptions)
            this.subscribeToRemoteParticipantInCall(this.call)
            this.localVideoView()
          } catch (error) {
            console.log(error)
          }
      },
      async placeVoiceCall () {
            const callOptions = {
            videoOptions: {
                localVideoStreams: undefined
            },
            audioOptions: {
                muted: false
            }
          }
          try {
            this.call = window.callAgent.startCall([{ communicationUserId: this.callingId }], callOptions)
            this.subscribeToRemoteParticipantInCall(this.call)
          } catch (error) {
            console.log(error)
          }
      },
      async hangUp () {
        if (this.call) {
          await this.call.hangUp()
          this.$emit('onClose')
          this.acceptCall = false
        }
      },
      reject () {
          if (this.currentCall) {
           this.currentCall.incomingCall.reject()
           console.log('reject')
           this.$emit('onClose')
        }
      },
      subscribeToStateChanged(call) {
        call.on('stateChanged', async () => {
            console.log(`Call state changed: ${call.state}`);  
        })
      },
      subscribeToParticipantVideoStreams (remoteParticipant) {
        remoteParticipant.on('videoStreamsUpdated', e => {
        e.added.forEach(v => {
          this.handleVideoStream(v)
        })
        });
        remoteParticipant.videoStreams.forEach(v => {
          this.handleVideoStream(v)
        });
      },
      subscribeToLocalVideoStreams (call) {
        call.on('localVideoStreamsUpdated', e => {
            e.added.forEach(async (lvs) => {
                this.localVideoStream = lvs
                await this.localVideoView()
            });
            e.removed.forEach(() => {
               this.removeLocalVideoStream()
            });
        })
      },
      subscribeToRemoteParticipantInCall(callInstance) {
        callInstance.on('remoteParticipantsUpdated', e => {
          e.added.forEach( p => {
            this.subscribeToParticipantVideoStreams(p);
          })
        }); 
        callInstance.remoteParticipants.forEach( p => {
          this.subscribeToParticipantVideoStreams(p);
        })
      },
      handleVideoStream(remoteVideoStream) {
        remoteVideoStream.on('isAvailableChanged', async () => {
          if (remoteVideoStream.isAvailable) {
              this.remoteVideoView(remoteVideoStream);
          } else {
              this.rendererRemote.dispose();
          }
        });
        if (remoteVideoStream.isAvailable) {
          this.remoteVideoView(remoteVideoStream);
        }
      },
      async remoteVideoView(remoteVideoStream) {
          this.rendererRemote = new VideoStreamRenderer(remoteVideoStream);
          const view = await this.rendererRemote.createView();
          this.$refs.remoteVideo.appendChild(view.target)
      },
      async localVideoView() {
          this.rendererLocal = new VideoStreamRenderer(this.localVideoStream);
          const view = await this.rendererLocal.createView();
          this.$refs.localVideo.appendChild(view.target)
          this.$refs.localVideo.hidden = false
      },
      async removeLocalVideoStream() {
        this.rendererLocal.dispose()
        this.$refs.localVideo.hidden = true
      }
    },

}
</script>

<style scoped>
  .video-list {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
   .video-item-sub {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
    .video-item-sub-local {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 100%;
    z-index: 10;
    /* z-index: 10; */
    object-fit: fill;
  }
  .video {
    height: 50%;
  }
div.relative {
  position: relative;
  width: 100%;
  height: 100%;
} 

div.absolute {
  position: absolute;
  top: 60%;
  right: 0;
  width: 93px;
  height: 166px;
}
</style>