<template>
     <v-app-bar app color="primary" v-bind="$attrs"  v-on="inputListeners" >
           <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
    <v-app-bar-nav-icon @click="$router.push({name: (name)? name :'main'})" v-if="backward">
       <template slot="default">
           <v-btn icon color="natural" ><v-icon>mdi-arrow-left</v-icon></v-btn>
       </template>
    
         </v-app-bar-nav-icon>
         <v-spacer/>
  <div class="text-title-1 natural--text text-no-wrap">{{title}}</div> 
     <!-- <v-app-bar-title class="text-title-1 natural--text text-no-wrap">{{title}}</v-app-bar-title> -->
        <v-spacer/>
  <v-btn color="natural" v-if="mode!=='production'"  class="ma-2" @click="$emit('onCalling')" icon><v-icon>mdi-phone</v-icon></v-btn>
     </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'หัวข้อแสดงผล H4' 
    },
    backward:{
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
      return {
          mode: process.env.VUE_APP_MODE
      }
  },
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }

}
</script>

<style>

</style>