<template>
<v-app>
    <SingleAppBar fixed :title="topic" :backward="true" name="chat" @onCalling="onCallingDialog"/>
    <v-main class="natural lighten-4 fill-height">
      <v-container class="fill-height" fluid >
  <v-row class="fill-height">
     <v-col cols="12">
        <v-card class="fill-height" flat>
              <v-card-text>
                <v-row dense>
                  <template  v-for="(item, index) in messageList">
                  <v-col
                    cols="12"
                    :key="index"
                    v-if="item.type === 'text' && !(item.sender.communicationUserId === chatUser.user.communicationUserId)"
                  >
                    <v-avatar size="25" class="ma-1">
                      <v-img :src="require('@/assets/whiteavatar.svg')"/>
                    </v-avatar>
                    <v-chip id="chip-usage" class="text-wrap" >{{ item.content.message }}</v-chip>
                    <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <small>{{item.senderDisplayName}}</small>
                  </v-col>
                   <v-col
                    cols="12"
                    :key="index"
                    v-if="item.type === 'text' && (item.sender.communicationUserId === chatUser.user.communicationUserId)"
                    class="text-right"
                  >
                   <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <v-chip id="chip-usage" class="text-wrap" color="primary lighten-1">{{ item.content.message }}</v-chip>
                   
                  </v-col>
                     <v-col
                     cols="12"
                    :key="index"
                    v-if="item.type === 'html' && !(item.sender.communicationUserId === chatUser.user.communicationUserId)"
                  >
                   <v-avatar size="25" class="ma-1">
                      <v-img :src="require('@/assets/whiteavatar.svg')"/>
                    </v-avatar>
                   <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <v-row v-if="item.content.message">
                    <v-col>
                    <v-card max-width="200" @click="preview(item.content.message.replace(/amp;/g, ''))">
                    <v-img :src="item.content.message.replace(/amp;/g, '')"/>
                    </v-card>
                    </v-col>
                    <v-spacer/>
                    </v-row>
                  </v-col>

                   <v-col
                   cols="12"
                    :key="index"
                    v-if="item.type === 'html' && (item.sender.communicationUserId === chatUser.user.communicationUserId)"
                    align-self="end"
                    class="text-right"
                  >
                   <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <v-row>
                    <v-spacer/>
                    <v-col>
                    <v-card max-width="200" @click="preview(item.content.message.replace(/amp;/g, ''))">
                    <v-img :src="item.content.message.replace(/amp;/g, '')"/>
                    </v-card>
                    </v-col>
                    </v-row>
                  </v-col>
                  </template>
                  <v-col v-if="typingStatus" cols="12">กำลังพิมพ์....</v-col>
                  <v-col v-if="sending" cols="12">กำลังส่งข้อความ....</v-col>
                </v-row>
              </v-card-text>
               <v-card-text v-if="loading" >
                <v-skeleton-loader
          v-model="loading"
          type="list-item-three-line"
        ></v-skeleton-loader>
               </v-card-text>
            </v-card>
     </v-col>
  </v-row>
        <!-- <v-row class="fill-height">
        <v-col>
         <v-card class="fill-height" flat>
              <v-card-text>
                <v-row dense>
                  <template  v-for="(item, index) in messageList">
                  <v-col
                    cols="12"
                    :key="index"
                    v-if="item.type === 'text' && !(item.sender.communicationUserId === chatUser.user.communicationUserId)"
                  >
                    <v-avatar size="25" class="ma-1">
                      <v-img :src="require('@/assets/whiteavatar.svg')"/>
                    </v-avatar>
                    <v-chip id="chip-usage" class="text-wrap" >{{ item.content.message }}</v-chip>
                    <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <small>{{item.senderDisplayName}}</small>
                  </v-col>
                   <v-col
                    cols="12"
                    :key="index"
                    v-if="item.type === 'text' && (item.sender.communicationUserId === chatUser.user.communicationUserId)"
                    class="text-right"
                  >
                   <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <v-chip id="chip-usage" class="text-wrap" color="primary lighten-1">{{ item.content.message }}</v-chip>
                   
                  </v-col>
                     <v-col
                     cols="12"
                    :key="index"
                    v-if="item.type === 'html' && !(item.sender.communicationUserId === chatUser.user.communicationUserId)"
                  >
                   <v-avatar size="25" class="ma-1">
                      <v-img :src="require('@/assets/whiteavatar.svg')"/>
                    </v-avatar>
                   <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <v-row v-if="item.content.message">
                    <v-col>
                    <v-card max-width="200" @click="preview(item.content.message.replace(/amp;/g, ''))">
                    <v-img :src="item.content.message.replace(/amp;/g, '')"/>
                    </v-card>
                    </v-col>
                    <v-spacer/>
                    </v-row>
                  </v-col>

                   <v-col
                   cols="12"
                    :key="index"
                    v-if="item.type === 'html' && (item.sender.communicationUserId === chatUser.user.communicationUserId)"
                    align-self="end"
                    class="text-right"
                  >
                   <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <v-row>
                    <v-spacer/>
                    <v-col>
                    <v-card max-width="200" @click="preview(item.content.message.replace(/amp;/g, ''))">
                    <v-img :src="item.content.message.replace(/amp;/g, '')"/>
                    </v-card>
                    </v-col>
                    </v-row>
                  </v-col>
                  </template>
                  <v-col v-if="typingStatus" cols="12">กำลังพิมพ์....</v-col>
                  <v-col v-if="sending" cols="12">กำลังส่งข้อความ....</v-col>
                </v-row>
              </v-card-text>
               <v-card-text v-if="loading" >
                <v-skeleton-loader
          v-model="loading"
          type="list-item-three-line"
        ></v-skeleton-loader>
               </v-card-text>
            </v-card>
          </v-col></v-row> -->
      </v-container>
                  <v-file-input
                    :rules="rules"
                    accept="image/png, image/jpeg, image/bmp"
                    ref="file"
                    name="file"
                    @change="select"
                    style="display: none"
                  />
    <v-dialog v-model="callingDialog" persistent>
      <CallingDialogCard @onClose="callingDialog=false" :callingId="callingId" :key="callingDialog" :currentCall="currentCall"/>
    </v-dialog>
    <v-dialog v-model="imgPreview" persistent scrollable fullscreen>
<ChatImagePreview @onClose="imgPreview = false" :imgSrc="currentImg"/>
      </v-dialog>
      </v-main
    >
     <v-footer app padless>

             <v-row
      dense
    >
    <v-col cols="auto" class="mt-2">
        <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
           <v-btn height="48" class="mx-auto" large icon @click="choose"   v-bind="attrs" v-on="on">
                   <v-icon>mdi-file-image-outline</v-icon>
                 </v-btn>
      </template>
      <span>ส่งรูป</span>
    </v-tooltip>
    </v-col>
    <v-col class="mt-2">
      <v-text-field  v-model="message" solo @keydown="sendTyping"></v-text-field
      >
    </v-col>
    <v-col cols="auto" class="mt-2">
          <v-btn color="primary" height="48" large  @click="sendMessage">ส่ง</v-btn>
    </v-col>
             </v-row>
    </v-footer>
</v-app>
</template>

<script>
import SingleAppBar from "@/components/chat/ChatAppBar";
import CallingDialogCard from "@/components/chat/CallingDialogCard"
import ChatImagePreview from '@/components/chat/ChatImagePreview'
// import {postTokenCommService} from "@/api/"
// import { mapFields } from "vuex-map-fields";
import { EventBus } from '@/plugins/event-bus'
import { initCall } from '@/plugins/communication'
import { uploadChatImage } from '@/api/'
import reduce from 'image-blob-reduce'

export default {
  name: "ChatArea",
  components: {
    SingleAppBar,
    CallingDialogCard,
    ChatImagePreview
  },
  props: ['topic','threadId'],
  data() {
    return {
      state: false,
      items: [],
      loading: false,
      threads: null,
      chatUser: null,
      messageList: [],
      message: '',
      typingStatus: false,
      lastSentTypingNotificationDate: 0,
      sending: false,
      callingDialog: false,
      callingId: null,
      mode: process.env.VUE_APP_MODE,
      rules: [
        (value) => !value || value.size < 10000000 || 'ขนาดรูปต้องต่ำกว่า 10 MB'
      ],
      currentImg: null,
      imgPreview: false,
      currentCall: null
    }
  },
  methods: {
    preview (img) {
      this.currentImg = img
      this.imgPreview = true
    },
    choose () {
      return this.$refs.file.$refs.input.click()
    },
    async select (file) {
      if (file) {
        try {
          this.loading = true
          // eslint-disable-next-line new-cap
          const rec = new reduce()
          const blob = await rec.toBlob(file, { max: 1000 })
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = async (e) => {
            const arrBase64 = e.target.result.split(',')
            const data = {
              fileType: arrBase64[0].match(/:(.*?);/)[1],
              fileEncode: arrBase64[1]
            }
            const resp = await uploadChatImage(data)
            if (resp.data.code === 1) {
              const htmlStr = resp.data.result
              await this.sendHtmlMessage(htmlStr)
            }
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      }
    },
    async sendTyping () {
      const MINIMUM_TYPING_INTERVAL_IN_MILLISECONDS = 8000
      let currentDate = new Date()
      let timeSinceLastSentTypingNotificationMs = currentDate.getTime() - this.lastSentTypingNotificationDate;
      if (timeSinceLastSentTypingNotificationMs >= MINIMUM_TYPING_INTERVAL_IN_MILLISECONDS) {
        console.log('send typing')
        await this.threads.sendTypingNotification()
        this.lastSentTypingNotificationDate = currentDate
      }
    },
    displayTyping () {
      this.typingStatus = true
    },
    async onCallingDialog() {
      this.loading = true
      await initCall()
      // this.callingId = await this.getParticipants()
      this.callingId = '8:acs:86f53a83-613f-47db-ad24-460c94084617_0000000b-6c54-750f-8975-c93a0d00a36d'
      this.loading = false
      this.callingDialog = true
    },
    async getParticipants() {
        // <List users in a chat thread>
        const participants = this.threads.listParticipants();
        for await (const participant of participants) {
            return participant.id.communicationUserId
        }
    },
    async chatMessageReceived(e) {
        if(e.threadId === this.threadId) {
              this.typingStatus = false
              if(e.sender.communicationUserId !== window.communicationService.user.communicationUserId) {
                await this.threads.sendReadReceipt({chatMessageId: e.id})
              }
              this.getMessage()
        }
    },
    async typingIndicatorReceived(e) {
      if(e.threadId === this.threadId) {
             if(e.sender.communicationUserId !== window.communicationService.user.communicationUserId) {
            this.displayTyping()
            this.$vuetify.goTo(9999)
             }
      }
    },
    async readReceiptReceived(e) {
      if(e.threadId === this.threadId) {
        console.log('readReceiptReceived')
      }
    },
    async incomingCall(call) {
       this.currentCall = call
       this.callingDialog = true
    },
    async startChatThread () {
        this.chatUser = this.$communicationService
        this.threads = this.$chatClient.getChatThreadClient(this.threadId)
        await this.getMessage()
        // window.chatClient.startRealtimeNotifications();
        EventBus.$on("chatMessageReceived", this.chatMessageReceived);
        EventBus.$on("typingIndicatorReceived",this.typingIndicatorReceived)
        EventBus.$on("readReceiptReceived",this.readReceiptReceived)
        EventBus.$on("incomingCall", this.incomingCall)
    },
    async fetchData(){
      try {
        if (this.$chatClient) {
        this.loading = true
        this.startChatThread()
        await initCall()
        } 
      } catch (error) {
        console.log(error)
      }
    },
    async getMessage() {
      try {
        const messages = this.threads.listMessages();
        const messageData = []
        for await (const message of messages) {
          messageData.push(message)
        }
        this.messageList = messageData.sort(function(a, b) {
          var keyA = new Number(a.sequenceId),
          keyB = new Number(b.sequenceId);
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
      this.loading = false
      this.$vuetify.goTo(9999)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.sending = false
      }
    },
    async sendMessage(){
        if(this.message) {
          const sendMessageRequest =
            {
                content: this.message
            };
            let sendMessageOptions =
            {
                senderDisplayName: this.$auth.role.fullname,
                type: 'text'
            };
          this.sending = true
          this.$vuetify.goTo(9999)
          await this.threads.sendMessage(sendMessageRequest, sendMessageOptions);
          this.message=''
        }
    },
    async sendHtmlMessage (html) {
      if (html) {
        const sendMessageRequest =
            {
              content: html
            }
        const sendMessageOptions =
            {
              senderDisplayName: this.$auth.role.fullname,
              type: 'html'
            }
        this.sending = true
        this.$vuetify.goTo(9999)
        await this.threads.sendMessage(sendMessageRequest, sendMessageOptions)
        this.message = ''
      }
    }
  },
  destroyed () {
     EventBus.$off("chatMessageReceived", this.chatMessageReceived);
     EventBus.$off("typingIndicatorReceived",this.typingIndicatorReceived)
     EventBus.$off("readReceiptReceived",this.readReceiptReceived)
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
};
</script>

<style scoped>
#chip-usage {
  margin: 8px;
  height: auto;
  min-height: 32px;
}
</style>